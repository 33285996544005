export const DROPDOWN_STYLE = {
  OFF_CANVAS: 1,
  ON_PAGE: 2,
} as const

export const DROPDOWN_DIRECTION = {
  UP: 1,
  DOWN: 2,
} as const

export const DROPDOWN_PLACEMENT = {
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
} as const
