<template>
  <Teleport to="body">
    <div
      data-dropdown-container
      :class="cssClasses.container"
      :style="cssStyles.container"
      @click.self="close"
    >
      <div :class="cssClasses.backdrop" @click.self="close" />
      <div
        data-dropdown-content
        :class="cssClasses.dropdown"
        class="flex w-full flex-col"
      >
        <div data-dropdown-header :class="cssClasses.header">
          <slot name="header" />
        </div>
        <div
          data-dropdown-content
          :class="cssClasses.content"
          class="flex-grow overflow-auto"
        >
          <slot name="default" />
        </div>
        <div data-dropdown-footer :class="cssClasses.footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
const emit = defineEmits(['close'])

const { open, close, cssClasses, cssStyles } = useDropdown({
  classes: {
    dropdown: 'delay-200',
  },
  style: DROPDOWN_STYLE.OFF_CANVAS,
  onClose: () => emit('close'),
})

onMounted(async () => {
  await nextTick()
  open()
})

defineOptions({
  name: 'DropdownContentOffCanvas',
})
</script>
